import { Box, Text } from '@chakra-ui/react';
import cn from 'classnames';
import Link from 'next/link';
import { createRef, forwardRef, Fragment, RefObject, useRef } from 'react';
import React from 'react';

import { ReactComponent as ArrowRightIcon } from '@/app/_icons/arrow-right-white.svg';
import { ReactComponent as CloseIcon } from '@/app/_icons/CkClose_black.svg';
import { ReactComponent as LocationIcon } from '@/app/_icons/location-kia-logo.svg';
import { dealerLocation } from '@/constants/dealers/dealers.constants';
import { useSelectedDealerLocation } from '@/hooks/dealers/dealers.hook';
import { useRoutesConfig } from '@/hooks/routes-config';
import { DateService } from '@/services/date.service';
import { DealerItem } from '@/types/dealers/dealers.types';

import styles from './DealersList.module.scss';

interface ListItemProps {
  item: DealerItem;
}

const DealershipListItem = forwardRef<HTMLDivElement, ListItemProps>(
  ({ item }, ref) => {
    const { selectedLocation, setSelectedLocation } =
      useSelectedDealerLocation();

    const routes = useRoutesConfig();

    return (
      <Box
        className={cn(styles.dealerItem, {
          [styles.selectedDealerItem as string]:
            selectedLocation.id === item.id,
        })}
        onClick={() => {
          setSelectedLocation({
            id: item.id,
            lat: item.lat,
            lng: item.lng,
          });
        }}
        ref={ref as unknown as RefObject<HTMLDivElement>}
      >
        <Box className={styles.dealerNameWrapper}>
          <Box className={styles.locationName}>
            <LocationIcon className={styles.dealerIcon} />
            <Link
              href={`${routes.dealershipDetail.path}/${item.name
                .toString()
                .replace(/ /g, '-')
                .toLowerCase()}`}
            >
              <h3 className={styles.dealerName}>{item.name}</h3>
            </Link>
            {selectedLocation.id === item.id && (
              <button
                className={styles.mobileDealerCardClose}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedLocation({
                    id: '',
                    ...dealerLocation,
                  });
                }}
              >
                <CloseIcon width={24} height={24} />
              </button>
            )}
          </Box>
        </Box>
        {item.mainAddress && (
          <Text className={styles.dealerStreet}>
            {item.mainAddress}, {item.secondaryAddress}
          </Text>
        )}

        <Box className={styles.dealerDetailsWrapper}>
          <Box>
            <Text className={styles.dealerPhone}>
              <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
            </Text>
            <Text className={styles.dealerWorkHours}>
              {item.operatingHours.map((day, index) => {
                const isToday = DateService.isCurrentDay(day.day_of_the_week);
                const currentTime = DateService.getCurrentTime();

                if (isToday) {
                  if (
                    currentTime > +day.openTime.slice(0, 2) &&
                    currentTime < +day.closeTime!.slice(0, 2)
                  ) {
                    return (
                      <Fragment key={index}>
                        <span className={styles.openedHours}>Open: </span>{' '}
                        {day.openTime && `${day.openTime} - ${day.closeTime}`}
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={index}>
                        <span className={styles.closedHours}>Closed: </span>{' '}
                        {day.openTime && `${day.openTime} - ${day.closeTime}`}
                      </Fragment>
                    );
                  }
                }
              })}
            </Text>
          </Box>
          <Link
            role="link"
            href={`${routes.dealershipDetail.path}/${item.name
              .toString()
              .replace(/ /g, '-')
              .toLowerCase()}`}
          >
            <button
              role="button"
              aria-label="View details"
              className={styles.detailsButton}
              onClick={(e) => e.stopPropagation()}
            >
              View details
              <ArrowRightIcon className={styles.arrowRightIcon} />
            </button>
          </Link>
        </Box>
      </Box>
    );
  },
);

DealershipListItem.displayName = 'DealershipListItem';

interface Props {
  isMobile?: boolean;
  dealers: DealerItem[];
}

export const DealersList = ({
  isMobile = false,
  dealers: dealerList,
}: Props) => {
  const { selectedLocation } = useSelectedDealerLocation();

  const arrLength = dealerList.length;
  const itemRefs = useRef<Array<RefObject<HTMLDivElement>>>(
    Array(arrLength)
      .fill(null)
      .map(() => createRef<HTMLDivElement>()),
  );

  if (itemRefs.current?.length !== arrLength) {
    itemRefs.current = Array(arrLength).map(
      (id) => itemRefs.current![id] || createRef(),
    );
  }

  const item = itemRefs?.current[parseInt(selectedLocation.id)]?.current;

  if (item) {
    item.scrollIntoView();
  }

  return (
    <Box
      className={cn(styles.dealersListWrapper, {
        [styles.dealersListWrapperHeight as string]:
          isMobile && selectedLocation?.id !== '',
      })}
      height={{ base: 180, sm: 375, md: 655 }}
      overflowY={'auto'}
      paddingStart={{ base: 0, lg: 5 }}
      paddingEnd={{ base: 0, lg: 5 }}
    >
      {dealerList.length > 0 ? (
        dealerList.map((item, index) => {
          // on mobile only show the first closest dealership item when one is selected
          if (isMobile && selectedLocation?.id !== '' && index) {
            return null;
          }

          return (
            <DealershipListItem
              key={item.id}
              item={item}
              ref={itemRefs.current[index]}
            />
          );
        })
      ) : (
        <Text className={styles.dealersNoResults}>No results...</Text>
      )}
    </Box>
  );
};
