import { Box, Text } from '@chakra-ui/react';
import cn from 'classnames';
import { createRef, useRef } from 'react';
import React from 'react';

import { ReactComponent as CloseIcon } from '@/app/_icons/CkClose_black.svg';
import { ReactComponent as LocationIcon } from '@/app/_icons/location-kia-logo.svg';
import styles from '@/app/find-a-dealer/_components/dealership-locations/DealersList.module.scss';
import { panelbeaterLocation } from '@/constants/panelbeaters/panelbeaters.constants';
import { useSelectedDealerLocation } from '@/hooks/dealers/dealers.hook';
import { DealerItem } from '@/types/dealers/dealers.types';
import { PanelbeaterItem } from '@/types/panelbeaters/panelbeaters.types';

interface ListItemProps {
  item: PanelbeaterItem;
}

const PanelBeaterListItem = ({ item }: ListItemProps) => {
  const { selectedLocation, setSelectedLocation } = useSelectedDealerLocation();

  return (
    <Box
      className={cn(styles.dealerItem, {
        [styles.selectedDealerItem as string]: selectedLocation.id === item.id,
      })}
      onClick={() => {
        setSelectedLocation({
          id: item.id,
          lat: item.lat,
          lng: item.lng,
        });
      }}
    >
      <Text className={styles.visuallyHidden} as="h1">
        Find your nearest Kia approved Panel Beater
      </Text>
      <Box className={styles.dealerNameWrapper}>
        <Box className={styles.locationName}>
          <LocationIcon className={styles.dealerIcon} />

          <h3 className={styles.dealerName}>{item.name}</h3>

          {selectedLocation.id === item.id && (
            <button
              className={styles.mobileDealerCardClose}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedLocation({
                  id: '',
                  ...panelbeaterLocation,
                });
              }}
            >
              <CloseIcon width={24} height={24} />
            </button>
          )}
        </Box>
      </Box>
      {item.mainAddress && (
        <Text className={styles.dealerStreet}>{item.mainAddress}</Text>
      )}

      <Box className={styles.dealerDetailsWrapper}>
        <Box>
          <Text className={styles.dealerPhone}>
            <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  isMobile?: boolean;
  panelBeaters: PanelbeaterItem[] | DealerItem[];
}

export const PanelBeaterList = ({
  isMobile = false,
  panelBeaters: panelBeatersList,
}: Props) => {
  const { selectedLocation } = useSelectedDealerLocation();

  const arrLength = panelBeatersList.length;
  const itemRefs = useRef<any[]>([]);

  if (itemRefs.current.length !== arrLength) {
    itemRefs.current = Array(arrLength).map(
      (id) => itemRefs.current[id] || createRef(),
    );
  }

  if (itemRefs && selectedLocation.id) {
    itemRefs?.current[selectedLocation.id as any]?.scrollIntoView();
    window.scrollTo(0, 0);
  }

  return (
    <Box
      className={cn(styles.dealersListWrapper, {
        [styles.dealersListWrapperHeight as any]:
          isMobile && selectedLocation?.id !== '',
      })}
      height={{ base: 180, sm: 375, md: 655 }}
      overflowY={'auto'}
      paddingStart={{ base: 0, lg: 5 }}
      paddingEnd={{ base: 0, lg: 5 }}
    >
      {panelBeatersList.length > 0 ? (
        panelBeatersList.map((item, index) => {
          // on mobile only show the first closest dealership item when one is selected
          if (isMobile && selectedLocation?.id !== '' && index) {
            return null;
          }

          return (
            <PanelBeaterListItem
              key={item.id}
              item={item as any}
              // ref={(el: any) => (itemRefs.current[item.id as any] = el)}
            />
          );
        })
      ) : (
        <Text className={styles.dealersNoResults}>No results...</Text>
      )}
    </Box>
  );
};
